import React from "react"
import ContactForm from "./contactForm"

const ProcessStep = ({
  title,
  description,
  addContactform,
  toggleContactForm,
  visible,
}) => (
  <div className="process-step">
    <span className="process-step__bullet" />
    <h2 className="process-step__title">{title}</h2>
    <div className="process-step__description">{description}</div>
    {addContactform ? (
      <>
        <span
          className="process-step__contact-link"
          onClick={() => toggleContactForm()}
        >
          Kontakt meg nå
        </span>
        <ContactForm visible={visible} />
      </>
    ) : null}
  </div>
)

export default ProcessStep
