import React from "react"
import Layout from "../components/layout"
import ProcessStep from "../components/processStep"
import { graphql } from "gatsby"

class HowItWorks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processSteps: null,
      showContactForm: false,
    }
    this.toggleContactForm = this.toggleContactForm.bind(this)
  }
  render() {
    const processStepsData = this.props.data.allSanityProcessStep.nodes
    processStepsData.sort((a, b) => {
      return a.number - b.number
    })
    let processSteps = processStepsData.map(step => {
      return (
        <ProcessStep
          key={step.number}
          title={step.title}
          description={step.description}
          addContactform={step.addContactform}
          toggleContactForm={this.toggleContactForm}
          visible={this.state.showContactForm}
        />
      )
    })
    return (
      <Layout>
        <div className="wrapper wrapper--desktop-width">
          <div className="margin-wrapper">
            <h2 className="center-align">Slik fungerer det</h2>
            <div className="process-steps-container">{processSteps}</div>
          </div>
        </div>
      </Layout>
    )
  }

  toggleContactForm() {
    this.setState({ showContactForm: !this.state.showContactForm })
  }
}

export default HowItWorks

export const query = graphql`
  query {
    allSanityProcessStep(filter: { location: { eq: "howItWorks" } }) {
      nodes {
        title
        description
        number
        addContactform
      }
    }
  }
`
